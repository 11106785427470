import React from 'react';
import { graphql } from 'gatsby';
import {
  BlogHeader,
  BlogPagination,
  BlogPostGrid,
  BlogTemplateWrapper,
} from 'partials';
import { PageHelmet } from 'components/pages';
import { liftNode, formatTitle } from 'utils';

export default class BlogLandingTemplate extends React.Component {
  componentDidMount() {
    const { data, location } = this.props;
    const { title } = data.allGhostSettings.edges[0].node;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Custom-PageView',
      pagePath: `${location.pathname}`,
      pageTitle: `${formatTitle(title)}`,
    });
  }

  render() {
    const { data, pageContext } = this.props;
    const { title, description } = data.allGhostSettings.edges[0].node;
    const posts = data.allGhostPost.edges.map(liftNode);

    return (
      <BlogTemplateWrapper>
        <PageHelmet title={title} description={description} />
        <div className="blog-context">
          <BlogHeader isHome />
          <BlogPostGrid posts={posts} />
          <BlogPagination pageContext={pageContext} />
        </div>
      </BlogTemplateWrapper>
    );
  }
}

export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      limit: $limit
      skip: $skip
      filter: { slug: { ne: "data-schema" } }
    ) {
      edges {
        node {
          slug
          title
          feature_image
          tags {
            slug
          }
          published_at_pretty: published_at(formatString: "MMMM D, YYYY")
          authors {
            name
          }
        }
      }
    }
    allGhostSettings {
      edges {
        node {
          title
          description
        }
      }
    }
  }
`;
